import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import useAssignProject from "../../hooks/useAssignProject";
import ErrorBox from "../FormikComponents/ErrorBox";
import SelectDropDownAsync from "../FormikComponents/SelectDropDownAsync";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Loader from "../Loader/Loader";

interface AssignProjectObj {
    project_id: string;
}

interface AssignProjectModalProps {
    artisan_id: string;
    action: string;
}
// {
//     "project_artisan_id": 1,
//     "project_id": "16ed7bc1-5ce9-4c84-87f4-87ea15ccc7b6",
//     "artisan_id": "SUN-BART-5136225636",
//     "created_at": "2024-07-27T08:02:55.641Z",
//     "updated_at": "2024-07-27T08:02:55.641Z",
//     "project_name": "Test Project",
//     "project_contact_details": {
//         "person_phone": "3892382320",
//         "person_name": "Rachit Gupta"
//     },
//     "project_location": "Satna"
// }
interface ProjectType {
    project_artisan_id: number;
    project_id: string;
    artisan_id: string;
    project_name: string;
    project_contact_details: {
        person_phone: string;
        person_name: string;
    };
    project_location: string;

}

const AssignProjectModal: React.FC<AssignProjectModalProps> = (props) => {
    const { artisan_id, action } = props;

    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [commonError, setCommonError] = useState("");
    const queryClient = useQueryClient();
    const initialValues: AssignProjectObj = {
        project_id: "",
    };
    const { handleProjectAssign } = useAssignProject();

    const validationSchema = Yup.object().shape({
        project_id: Yup.string().required("Project Id is required"),
    });

    const handleSubmit = async (values: AssignProjectObj) => {
        if (!values.project_id) {
            setCommonError("Project Id is required");
            return;
        }
        await handleProjectAssign({
            action: action,
            project_id: values.project_id,
            artisan_id: artisan_id,
        });
    };

    const getArtisanProjects = async () => {
        const { data } = await axiosInstance.get(`/project/projects/${artisan_id}`);
        return data.data.projects;
    }

    const { data, error, isLoading } = useQuery<
        ProjectType[] | undefined,
        any,
        ProjectType[],
        any
    >(["getArtisanProjects", artisan_id], async () => await getArtisanProjects())

    return (
        <div className="flex flex-col justify-center items-center">
            <img src={logo} alt="logo" height={80} width={80} />
            <div id="projects" className="max-h-72 px-10 py-6 mt-4 overflow-auto w-full">
                {isLoading ? <Loader/> : data?.length !== 0 ? data?.map((project: ProjectType, index) => (

                    <div className="grid grid-cols-3 gap-10 mb-12" key={index}>
                        <div className="flex items-start">
                            <h3 className="text-base font-semibold mr-4">Project Name: </h3>
                            <p className="text-base font-medium text-green-500">{project?.project_name}</p>
                        </div>
                        <div className="flex items-start">
                            <h3 className="text-base font-semibold mr-4">Person Name: </h3>
                            <p className="text-base font-medium text-green-500">{project?.project_contact_details?.person_name}</p>
                        </div>
                        <div className="flex items-start">
                            <h3 className="text-base font-semibold mr-4">Person Phone: </h3>
                            <p className="text-base font-medium text-green-500">{project?.project_contact_details?.person_phone}</p>
                        </div>
                        <div className="flex items-start">
                            <h3 className="text-base font-semibold mr-4">Project Location: </h3>
                            <p className="text-base font-medium text-green-500">{project?.project_location}</p>
                        </div>

                        <hr className="col-span-3" />
                    </div>
                )) :
                    (<div className="text-xl font-semibold">No Project Assigned to this Artisan</div>)}

            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    setFieldValue,
                    setFieldTouched,
                    values,
                    errors,
                    touched,
                }) => (
                    <Form
                        className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
                        style={{ minWidth: "50vw" }}
                    // encType="multipart/form-data"
                    >

                        <div className="flex flex-col w-full">
                            <SelectDropDownAsync
                                label="Project List"
                                id="project_id"
                                name="project_id"
                                classes=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                loadOptions={async (inferString: string) => {
                                    try {
                                        const response =
                                            await axiosInstance.get("/project/all");
                                        let requiredData: any = [];
                                        for (
                                            let i = 0;
                                            i < response.data.data.length;
                                            i++
                                        ) {
                                            if (response?.data?.data[i]?.project_name?.toLowerCase()?.replace(" ", "")?.includes(inferString.toLowerCase().replace(" ", ""))) {
                                                requiredData.push({
                                                    label: response.data.data[i]
                                                        .project_name,
                                                    value: response.data.data[i]
                                                        .project_id,
                                                });
                                            }
                                        }
                                        return requiredData;
                                    } catch (error) {
                                        return [
                                            {
                                                label: "Error. Something went wrong!!",
                                                value: "error",
                                            },
                                        ];
                                    }
                                }}
                            />
                            {/* {values?.project_id && (
                                <div className="flex gap-2">
                                    <p>Select Project Id : </p>
                                    <p className="font-bold">
                                        {values?.project_id}
                                    </p>
                                </div>
                            )} */}

                            <div className="flex gap-2">
                                <p>Selected Artisan ID: </p>
                                <p className="font-bold">
                                    {artisan_id}
                                </p>
                            </div>

                        </div>
                        {commonError && (
                            <div className="common-error mb-2 text-center">
                                <ErrorBox msg={commonError} />
                            </div>
                        )}
                        <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
                            <SubmitBtn
                                text="Assign Project"
                                isSubmitting={isSubmitting}
                                classes="text-sm"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AssignProjectModal;
